




















import {Component, Mixins, Vue} from 'vue-property-decorator'
import Documents from '@/modules/shipments/views/detail/components/Documents.vue'
import ECMR from '@/modules/shipments/views/detail/components/ECMR.vue'
import PlanningService from "@/modules/planning/PlanningService";
import BaseDataTable from "@/general/components/general/BaseDataTable.vue";
import InfoCard from "@/general/components/common/InfoCard.vue";
import PlanningStops from "@/modules/planning/views/PlanningStops.vue";
import {ListSelfPlannings_ListSelfPlannings_items} from "@/generated/ListSelfPlannings";

@Component({
  components: {
    PlanningStops,
    InfoCard,
    BaseDataTable,
    Documents,
    ECMR
  }
})
export default class ScanListPlannings extends Mixins(PlanningService) {
  plannings: ListSelfPlannings_ListSelfPlannings_items[] = []

  selectPlanning(planning: ListSelfPlannings_ListSelfPlannings_items) {
    // @ts-ignore
    this.$router.push({
      name: 'scan-planning',
      params: {
        // @ts-ignore
        id: planning.id
      },
      query: { ...this.$route.query }
    })
  }
  
  created() {
    this.$store.commit('isOnConsignment', false)
    this.$store.commit('isOnStatusUpdate', false)
    this.$store.state.transport.ecmrs = new Map()
    this.$store.state.transport.newEcmrs = true
    this.fetchPlannings()
  }

  fetchPlannings() {
    this.getSelfPlanningOverview(5).then(result => {
      // Show most recent at top
      this.plannings = result.items.reverse()
      this.$store.commit('loading/set', false)
    })
  }

  getHeader(planning: ListSelfPlannings_ListSelfPlannings_items): string {
    return [planning.name, this.$options.filters!!.date(planning.startDate)].join(" | ")
  }
}
